@import "../../../../stylesheets/colors";

button.ui.button.Payment__Change {
  color: $chambray !important;
  background: transparent !important;
  border: 1px solid $chambray;
  border-radius: 15px;
  max-height: 2em;
  line-height: 0.2em !important;
  min-width: 8em;
  &:hover {
    background-color: $chambray !important;
    color: $white !important;
  }
}

.field.Payment__Promo__Button {
  & .button {
    color: $chambray;
    background: transparent;
    border: 1px solid $chambray;
    border-radius: 15px;
    min-width: 8em;
    &:hover {
      background-color: $chambray;
      color: $white;
    }
  }
}

.PaymentMethod-CardElement {
  padding: 0.4rem;
}
