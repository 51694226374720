@import "../../../stylesheets/colors";

.Login-Paragraph {
  color: $gulf;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
}

.column.row.Login-Tabs {
  border-bottom: 1px solid $primary;
  padding-bottom: 0;
}

.Login-Tabs__Tab {
  font-size: 0.875rem;
  color: $waterloo;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover { color: $primary; }

  &--active {
    border-bottom: 5px solid $primary;
    color: $primary;
    font-size: 1.125rem;
  }
}

.Login-Tabs__Form {
  padding: 2rem 0 !important;
}
