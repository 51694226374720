@import "../../../stylesheets/colors";

.Card {
  min-height: 80px;
  border-radius: 2px;
  box-shadow: 0 3px 5px 0 #eee;
  background-color: #fff;

  .card-header {
    text-align: left;
    @media (max-width: 992px) {      
      text-align: center;
    }
  }

  .card-actions {
    text-align: right;
    @media (max-width: 992px) {      
      text-align: center !important;
      padding: 0px !important;
    }
  }

  .card-content {
    text-align: left;
    @media (max-width: 992px) {      
      text-align: center !important;
    }
  }

  .margin {
    padding: 12px 20px;
  }

  .autoHide { 
    visibility: hidden;
    position: absolute;
    right: 1em;
    top: 1em;
   }
  &:hover .autoHide { visibility: visible; }

  button { border: 1px solid transparent; outline: none; cursor: pointer; }
  header {
    display: flex;
    align-items: center;
  
    h1 {
      flex: 1;
      font-size: 16px;
      font-weight: 900;
      letter-spacing: 2px;
      color: $gulf;
      margin: 0px;
      text-transform: uppercase;
    }
  }
}