@import "../../../stylesheets/colors";

.BusinessType {
    &__Text>p{
      font-size: 1.3em;
    }
}

.ui.big.button.BusinessType__Buttons-Back {
    background-color: $hint-of-red;
}

.ui.input.BusinessType__Details-Input {
    width: 80%;
    max-width: 500px;
}

.BusinessType__Search > .ui.icon.input {
    width: 100%;
    & >input {
        border-radius: .28571429rem;
    }
}
.ui.category.search {
    & >.results .category{
        display: grid;
        overflow: hidden;
    }

    & >.results .category .result:hover {
        background-color: $hint-of-red;
    }

    & >.results .category {
        & >.name {
            width: 100%;
            background-color: $white;
            color: $black;
        }
    }

    & .results {
        width: 100%;
        max-width: 500px;
        right: 0;
        left: 0;
        margin: auto;
    }
}

.ui.category.search .results.animating, .ui.category.search .results.visible {
    border: solid 1px $anakiwa;
}

.ui.search{
    & >.results .result .title {
        color: rgba(0, 0, 0, 0.65);
    }
    & > .results {
        text-align: center;
    }
}
