@import "../../../../stylesheets/colors";

.ui.grid.AccountTable__Container {
  padding: 12px;
  margin-top: 8px;

  > table {
    color: $gulf;
    > thead tr th { color: $chambray; }
  }
}

