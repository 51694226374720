div.Professional__Card__Invitation {
  padding: 0;
  padding-top: 1em;
}

div.ConfirmSendInvitation__Container {
  padding-right: 2em;
  padding-left: 2em;
}

button.ui.button {
  &.ConfirmSendInvitation__Button__Cancel {
    text-transform: uppercase;
    color: #474747;
    background: #fff none;
  }

  &.ConfirmSendInvitation__Button__SendInvite {
    text-transform: uppercase;
  }

}
.ConfirmSendInvitation__Buttons {
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;
}

h2.ui.header {
  &.ConfirmSendInvitation__Header {
    text-transform: uppercase;
    color: #7c7c7c;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5em;
    margin-bottom: 1.5em;

    > .content {
      padding-right: 0.25em;
      padding-left: 0.25em;
    }
  }
}

.ui.header {
  > .icon.ConfirmSendInvitation__Icon {
    font-size: 0.8em;
  }
}

.ConfirmSendInvitation__Icon {
  color: #fb2c37;
}

.ConfirmSendInvitation__Invitee {
  color: #ef778b;
}
