.ChooseUs {
  @media (max-width: 992px) {
    & {
      margin-top: 6rem;
    }
  }
  &--title {
    text-align: center;

    text-transform: uppercase;
  }
  &--description {
    text-align: center;
  }
  &--cards {
    display: flex;
    align-items: stretch;
    height: 100%;

    &--card {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 357px;
      width: 100%;

      &:hover {
        box-shadow: 0 0rem 2.5rem rgba(0, 0, 0, 0.2) !important;
        transition: 0.15s ease-in;
      }
      &-img {
        width: 120px !important;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
      }
      &-title {
        font-weight: bolder;
        display: flex;
        justify-content: center;
        font-size: 1.6rem;
        align-items: baseline;
      }
      &-body {
        flex: 1 1 auto;
        padding: 1.25rem;
      }
      &-text {
        text-align: center;
        padding: 3px;
        padding-left: 13px;
        padding-right: 13px;
        font-size: 0.9rem;
      }
    }
  }
}
