.ShowInvitee__Container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-right: 2em;
  padding-left: 2em;
  @media (max-width: 992px) { flex-direction: column; }
}

.show-invitee-invitee-details {
  flex-grow: 1;
}

.show-invitee-invitee-status {
  flex-grow: 1;
}

.show-invitee__invitee-actions {
  flex-grow: 1;
}

button.ui.button {
  &.ShowInvitee__RevokeButton {
    background: none;
    border: 0;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
    margin-right: 1em;
  }

}

.ShowInvitee__Status--Accepted {
  color: #1ac475;
}
