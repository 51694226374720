.HowItWorks {
  background-image: url('../../../images/videosection-bg.png');
  padding-bottom: 4rem !important;
  padding-top: 3rem !important;
  margin-top: 65px;

  &--title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2em !important;
  }
  &--video-section {
    margin: 0;
    display: flex;
    justify-content: space-around;

    &--wrapper {
      position: relative;
      width: 100%;
      margin: 0 auto;
      border-radius: 4px;
      padding: 1rem;
      background: white;
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;

      @media (min-width: 992px) {
        & {
          flex: 0 0 66.666667%;
          max-width: 66.666667%;
        }
      }
      .embed-responsive {
        background-color: #fff !important;
        border-radius: 0.25rem !important;
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
      }
      .embed-responsive-16by9::before {
        padding-top: 56.25%;
      }
      .embed-responsive::before {
        display: block;
        content: '';
      }
      .embed-responsive .embed-responsive-item,
      .embed-responsive iframe,
      .embed-responsive embed,
      .embed-responsive object,
      .embed-responsive video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }
}
