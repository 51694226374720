.TaxForm {
  font-family: arial;

  .bl{
    border-left: 1px solid #000;
    width: 80%;
  }
  .br{
    border-right: 1px solid #000;
  }
  .bt{
    border-top: 1px solid #000;
  }
  .bb{
    border-bottom: 1px solid #000;
    display: block;
  }

  .border-top{
    border-top: 1px solid #000;
  }
  .border-bottom{
    border-bottom: 1px solid #000;
  }
  .border-right{
    border-right: 1px solid #000;
  }
  .border-left{
    border-left: 1px solid #000;
  }
  .pb-2
  {
    padding-bottom: 6px; 
  }
  .pt-2
  {
    padding-top: 6px; 
    display: block;
  }

  .TaxForm__Table {
    display: flex;
    align-items: center;

    &:last-child {
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
    }
  }

  table, 
  table.TaxForm__Transactions {
    margin: auto;
    border-spacing: unset;
  }

  table.TaxForm__Transactions {
    margin: auto;
    width: 90%;
  }

  .caution {
    margin: auto;
    min-height: 1rem;
  }

  h1,h2,h3,h4,h5{
    margin:0px;
  }
  .text-center{
    text-align: center !important;
  }
  .text-left{
    text-align: left;
  }
  .text-right{
    text-align: right !important;
  }
  p{
    margin: 0px;
    display: unset;
  }

  .p{
    padding: 0px !important;
  }
  .bold{
    font-weight: 600;
  }
  .pr{
    padding-right: 65px; 
  }
  .pl{
    padding-left: 181px;
  }

  input{
    border: 0;
    border-bottom: 1px dashed #000;
  }
  input[type="text"] {
    max-width: 100%;
  }

  .rotate {
    transform: rotate(-90deg);
    width: 5%;
  }

  .d-block {
    display: block;
  }
  .border-all {
    border: 1px solid #000;
  }

  .bg{
    background-color: #000;
    color:#fff;
  }

  &--SummaryGroup {
    display: grid;
    align-items: center;
    border-top: 1px solid black;
    border-bottom-color: transparent;
    padding-bottom: 1rem;

    .center { text-align: center; }
    .right { text-align: right; }
    .square { border-left: 1px solid black; border-bottom: 1px solid black; }
    // .borderBottomTransparent { border-bottom-color: transparent;  }
    .disabled { background: #f1eeee; }

    .categoryName {
      display: flex;
      > div:nth-child(2) {
        flex-grow: 1;
        background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
        background-position: bottom;
        background-size: 5px 2px;
        background-repeat: repeat-x;
      }
    }
    > div { padding: 2px 3px; }
    .firstCol {
      justify-self: center;
      padding: 5px;

      > div {
        transform: rotate(180deg);
        white-space: nowrap;
        writing-mode: vertical-lr;
      }
    }
  }
  &--SummaryGroup:last-of-type {
    border-bottom: 1px solid black;
  }
}
