@import "../../../stylesheets/colors";

.ui.inverted.popup.transition.visible {
  background-color: $pickled-bluewood;
}

.ToolTip-Box {
  &__QM > h5 > i.question.circle.icon {
    color: rgb(64, 81, 104);
    opacity: 1 !important;
    font-size: 1.3em;
    margin: 0.3em;
  }
}
