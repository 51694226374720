@import "../../../stylesheets/colors";

.fieldset-hoc {
  padding: 0px;
  border: 1px solid #ddd;
  border-radius: 3px;

  legend { height: 1em; line-height: 1em; text-align: left; }
  .ui.selection.dropdown, input { border: 1px solid transparent !important; width: 100%; }
  .ui.icon.input { width: 100%; }

  &.active { border-color: $primary; }
  &.active legend { color: $primary; padding: 0px; margin: 0px; }
  &.active legend.has-label { padding: 0px 7px; margin: 0px 5px; }
}
