.Banner {
  background-image: url('../../../images/navbarBelow.png') !important;
  // min-height: 100vh;
  background: no-repeat;
  background-position: 0 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  &--content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 3rem;
    margin-top: 2rem;
    @media (max-width: 992px) {
      & {
        margin: 0px;
      }
    }
  }
  &--jumbotron {
    margin-bottom: 2rem;
    border-radius: 0.3rem;
    background: transparent;

    @media only screen and (min-width: 576px) {
      & {
        padding: 64px 32px;
      }
    }
    @media only screen and (min-width: 992px) {
      & {
        padding: 180px 32px;
      }
    }
  }

  &--art {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--main-title {
    font-size: 2.6rem;
    font-weight: 800;
    line-height: 0.8;
    color: #54546c;
    @media (max-width: 1300px) {
      & {
        font-size: 2rem;
      }
    }
    @media (max-width: 992px) {
      & {
        font-size: 1.5rem;
        /* font-weight: 800;
         color: #54546c; */
      }
    }
  }
}
