@import "../../../../stylesheets/colors";

.ui.segment.Detail-Table--Simplified {
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: 0;
  border: none;
  box-shadow: none;

  > table {
    color: #34495e;
    > thead { font-size: 11px; }
    > tbody tr td { font-size: 0.9rem; }
    > tbody tr td:nth-child(7) { color: #2aa7dc; }
    > tbody tr td:nth-child(8) { color: #3a5695; }
  }
}

.ui.segment.Detail-Table__Header {
  align-items: flex-end;
  background-color: $gulf;
  color: $white;
  display: flex;
  font-size: 1.25rem;
  font-weight: 700;
  height: 7rem;
  justify-content: space-between;
  text-transform: capitalize;
}

.ui.segment.Detail-Table__Pagination {
  margin-bottom: 1rem;

  & .ui.pagination.menu {
    border: 0;
    box-shadow: none;
  }

  & .ui.menu .item {
    border: solid 1px rgb(51, 76, 138);
    margin: 0 0.25rem;
    outline: none;

    &.active {
    border-color: $primary;
    background-color: $primary;
      color: $white;
    }
  }
}

.Detail-Table__Close-Icon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.9rem;
  cursor: pointer;
}
