.LandingPage {
  color: #54546c;
  font-family: 'Kanit', sans-serif;

  ::selection {
    background: #f48d9c; /* WebKit/Blink Browsers */
    color: white;
  }
  ::-moz-selection {
    background: #f48d9c; /* Gecko Browsers */
    color: white;
  }
  p:last-child {
    margin-bottom: 1em;
  }
  .text-center {
    text-align: center;
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }
  // to be changed
  .shadow-lg {
    box-shadow: 0 0rem 2rem rgba(0, 0, 0, 0.05) !important;
  }
  // to be changed
  .shadow-cs {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
}
