.show-invitee-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.ShowInvitee__Container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-right: 2em;
  padding-left: 2em;
}

.show-invitee-invitee-details {
  flex-grow: 1;
}

.show-invitee-invitee-status {
  flex-grow: 1;
}

.show-invitee__invitee-actions {
  flex-grow: 1;
}

.ShowInvitee__Name {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.ShowInvitee__Email {
  padding-top: 1em;
  padding-bottom: 1em;
}

button.ui.button {
  &.ShowInvitee__CancelButton {
    background: none;
    border: 0;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
    margin-right: 1em;
  }

  &.ShowInvitee__ResendButton {
    background: none;
    border: 0;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
  }
}

.ShowInvitee__Email {
  color: #7c7c7c;
}

.ShowInvitee__Status--Pending {
  color: #fd8407;
}
