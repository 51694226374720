.ui.basic.label.Signup-Form__Password-Label {
  position: absolute !important;
}

.resend-verification {
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: #4183C4;
  margin-top: 10px;

  &:hover {
    color: blue;
  }
}