.Modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    
    .ui.button.Modal__CloseButton {
        background-color: white;
        float: right;
    }
}
  
.modal-main {
    position:fixed;
    background: white;
    width: 50%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding-bottom: 2em;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}