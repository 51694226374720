@import "../../../../stylesheets/colors";

.ui.segment.Profit-And-Loss__Bottom {
  background-color: $alabaster;
}

.list.Profit-And-Loss__Group {
  position: relative;
}

.ui.list .item .header {
  display: flex !important;
  &.Profit-And-Loss__Group-Title {
    color: $gulf;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.ui.list .list.Profit-And-Loss__List {
  padding: 0 0 0 1rem !important;

  & > .item {
    padding: 0 !important;
  }

  &--padded {
    padding: 1rem 2rem 1rem 1rem !important;
  }
}

div.Profit-And-Loss__List-Item:not(.header) {
  cursor: pointer;
}

div.Profit-And-Loss__List-Item {
  align-items: center;
  color: $gulf;
  display: flex;
  justify-content: space-between;
  line-height: 1.8rem;

  & > div:first-child {
    width: 70%;
  }

  & > div:last-child {
    width: 30%;
    display: flex;
    justify-content: flex-end;
  }
}


.Profit-And-Loss__divider {
  position: absolute;
  left: 0;
  border-bottom: 1px solid $mercury;
  width: 100%;
}

.Profit-And-Loss__Category {
  &-Title {
    color: $chambray;
    font-weight: 700;
  }
  &-Amount {
    color: $chambray;
    font-weight: 700;
  }
}

.Profit-And-Loss__Item-Amount--asPrice {
  &::before {
    content: '$';
  }
}
