$primary: #F48D9C;
$alabaster: #F7F7F7;
$dusty-gray: #979797;
$hint-of-red: #F8F4F4;
$white: #FFFFFF;
$black: #000000;
$gulf: #34495e;
$anakiwa: #81B0D2;
$gray: #858585;
$waterloo: #7C8495;
$shamrock: #48d2a0;
$porcelain: #F7F8F9;
$curious-blue: #2BA7DC;
$cadet-blue: #B2BAC4;
$chambray: #3a5695;
$glacier: #729ec2;
$soft-peach: #F9F4F4;
$mercury: #e1e1e1;
$picton-blue: #30b0e0;
$pickled-bluewood: #35495D;
$kashmir-blue: #54718E;
