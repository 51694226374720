@import "../../../stylesheets/colors";

.Steps {
  font-weight: 900;
  color: $gray;

  &__Step {
    display: flex;
    justify-content: center;
  }

  &__Step-circle {
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $primary;
    width: 2rem;
    height: 2rem;
    position: relative;
  }

  &__Step-inner-circle {
    background-color: $waterloo;
    border-radius: 50%;
    bottom: 0;
    height: 0.5rem;
    margin: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 0.5rem;
  }

  .Steps__Step-progress-bar {
    bottom: 1rem;
    width: 80%;
    position: absolute;
    left: calc(100% - 80% - 60%);
    height: 0.0625rem;
    background-color: $waterloo;
  }

  &__Step-text {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }
}

.ui.grid .column.Steps__Step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column.Steps__Step--active {
  color: $primary;
  cursor: pointer;

  .Steps__Step-circle {
    background-color: $primary;
    border: none;
  }

  .Steps__Step-inner-circle {
    background-color: $white;
  }

  .Steps__Step-progress-bar {
    background-color: $primary;
  }
}
