@import "../../../stylesheets/colors";

.Section__Title {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.2rem;
  margin-top: 1.5rem;
  margin-bottom: 0.2rem;
  color: $primary;
}

.Sub__Section__Type {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin-bottom: 0.2rem;
  color: $gulf;
}

.field.NewItem__Close {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.ui.table thead th.Transactions__Header--HeaderCell, .ui.table tbody tr td {
  cursor: default;
  padding: 6px;
}

.ui.celled.table tr th {
  position: relative;
  & i.edit.icon {
    cursor: pointer;
    position: absolute;
    right: 12px;
    color: $primary;
    margin-left: 1rem;
  }
}

.Table__Section__Type {
  font-weight: bold;
  font-size: 1.2rem;
}

.Table__Category__Type {
  font-weight: bold;
  font-size: 1.1rem;
}

.Table__Category__Total {
  border-top: 2px dotted $gray;
}

.Table__Section__Total {
  border-top: 2px solid $gray;
  font-size: 1.1rem;
  font-weight: bold;

  &.Check {
    border-top: 2px solid $primary;
  }
}