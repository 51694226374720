@import "../../../stylesheets/colors";

.Header-Sections {
  display: flex;
  justify-content: space-between;
}

.Header-Sections__Section {
  position: relative;
  text-align: center;
  font-size: 1.125rem;
  color: $waterloo;
  width: 7.25rem;
  cursor: pointer;

  &--active {
    color: $chambray;
    &::after {
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 8px solid $chambray;
      content: '';
      height: 0;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      top: 1.65rem;
      width: 0;
    }
  }
}
