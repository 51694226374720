@import "../../../../../stylesheets/colors";

.Available-Plan {
  margin-bottom: 2rem;

  & > div .six.wide.column {
    text-align: center;
  }

  & .button.ui.button.Available-Plan__Subscribe {
    color: $chambray !important;
    background: transparent !important;
    border: 1px solid $chambray;
    border-radius: 15px;
    max-height: 2em;
    line-height: 0.2em !important;
    &:hover {
      background-color: $chambray !important;
      color: $white !important;
    }
  }

  & button.ui.button.Available-Plan__Current {
    background-color: $primary !important;
    border-radius: 15px;
    pointer-events: none;
    color: $white !important;
    min-width: 8em;
    max-height: 2em;
    line-height: 0.2em !important;
  }

  & h4.ui.header.Available-Plan__Subtitle {
    text-decoration: none;
    color: $primary;
  }
}

span.Subscription__Confirm_Text {
  font-size: 16px;
}

.field.Subscription__Promo__Button {
  & .button {
    color: $primary;
    background: transparent;
    border: 1px solid $primary;
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}

.ui.toggle.Promo_Radio {
  margin: 8px 0;
}

.six.wide.column.Available-Plan__Action {
  display: flex;
  align-items: center;
  justify-content: center;
}


.Available-Plan__Benefits-List li {
  margin: 0;
}