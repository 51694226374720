@import "../../../stylesheets/colors";

.No-Available-Plans {
  text-align: center;
  padding: 2em;
  margin-bottom: 1em;
  background-color:white;
  -webkit-box-shadow: 0px 3px 3px 1px rgba(0,0,0,0.07);
  -moz-box-shadow: 0px 3px 3px 1px rgba(0,0,0,0.07);
  box-shadow: 0px 3px 3px 1px rgba(0,0,0,0.07);
}

.ui.header.Current-Plan__Card-Title {
  color: $gulf;
}