@import "../../../stylesheets/colors";

.Confirm__Buttons {
  display: inline-flex;
}

.column.Confirm__Icon {
  > i {
    font-size: 4em;
    color: #E57373;
  }
}

.row.Confirm__Leyend {
  margin: 1em;
}

.row.Confirm__Container {
  background-color: #f3f3f4;
  padding: 1em;
}

.button.ui.button.Confirm__Buttons-Continue {
  background-color: $primary;
  color:white;
}