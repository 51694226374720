@import "../../../../../stylesheets/colors";

h4.ui.header.Current-Plan__Subtitle {
  text-decoration: none;
}

ul.Current-Plan__Description > li {
  margin: 0;
  padding: 0;
}

.Card.Current-Plan__Title > div  {
  & > header {
    text-align: center;
  }
  & > header > h1 {
    color: $primary;
  }
}

.margin.No-Current-Plan {
  text-align: center;
  margin-bottom: 1em;
}

.ui.active.centered.inline.loader.Current-Plan-Loading {
  margin-bottom: 1em;
}

.margin.No-Current-Plan {
  text-align: center;
}

span.Current-Plan__Expired {
  color: #DD6766;
  font-size: larger;
}

.six.wide.column.Current-Plan__Unsubscribe {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  & > .Current-Plan__Unsubscribe-Btn {
    color: $chambray !important;
    background: transparent !important;
    border: 1px solid $chambray;
    border-radius: 15px;
    max-height: 2em;
    line-height: 0.2em !important;
    &:hover {
      background-color: $chambray !important;
      color: $white !important;
    }
  }
}