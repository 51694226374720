.ProfileDetailsEdit {
  img { margin: 0px auto; }

  &__accountType {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.8px;
    color: #3a5695;
  }

  h6 {
    margin: 0px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.8px;
    color: #b2bac4;
  }
}