.Pricing {
  // margin-top: 50px;
  padding-top: 70px;

  &--heading {
    text-transform: uppercase;

    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    text-align: center;
  }
  &--description {
    text-align: center;
  }
  &--cards {
    display: flex;
    align-items: stretch;
    height: 100%;

    @media only screen and (max-width: 992px) {
      & {
        margin: auto;
      }
    }
    @media only screen and (min-width: 768px) {
      & {
        max-width: 395px;
      }
    }
    @media only screen and (min-width: 992px) {
      &-first {
        margin-left: auto;
      }
    }

    &--card {
      //card card-price shadow-lg p-1  mb-2 pb-3 bg-white rounded
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding: 14px !important;
      padding-top: 1.75rem !important;
      padding-bottom: 1.75rem !important;
      background: white;
      border-radius: 0.25rem !important;
      border: 1px solid rgba(0, 0, 0, 0.125);
      box-shadow: 0 0rem 2rem rgba(0, 0, 0, 0.05) !important;

      &:hover {
        box-shadow: 0 0rem 2.5rem rgba(0, 0, 0, 0.2) !important;
        transition: 0.15s ease-in;
      }
      &-img {
        width: 110px !important;
        /* margin-top: 20px; */
        margin-left: auto;
        margin-right: auto;
      }
      &-body {
        &--switch {
          display: flex;
          justify-content: space-around;
          border-bottom: 1.2px solid #e0e0e0c2;
          padding-bottom: 10px;
          margin-top: -30px;
          &-text {
            margin-top: 1px;
            font-size: 18px;
            color: #54546c;
            font-weight: 800;
          }
          &-amount {
            color: #f48d9c;
          }
        }

        .tgl {
          display: none;
        }
        .tgl,
        .tgl:after,
        .tgl:before,
        .tgl *,
        .tgl *:after,
        .tgl *:before,
        .tgl + .tgl-btn {
          box-sizing: border-box;
        }
        .tgl::-moz-selection,
        .tgl:after::-moz-selection,
        .tgl:before::-moz-selection,
        .tgl *::-moz-selection,
        .tgl *:after::-moz-selection,
        .tgl *:before::-moz-selection,
        .tgl + .tgl-btn::-moz-selection {
          background: none;
        }
        .tgl::selection,
        .tgl:after::selection,
        .tgl:before::selection,
        .tgl *::selection,
        .tgl *:after::selection,
        .tgl *:before::selection,
        .tgl + .tgl-btn::selection {
          background: none;
        }
        .tgl + .tgl-btn {
          outline: 0;
          display: block;
          width: 4em;
          height: 2em;
          position: relative;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        .tgl + .tgl-btn:after,
        .tgl + .tgl-btn:before {
          position: relative;
          display: block;
          content: '';
          width: 50%;
          height: 100%;
        }
        .tgl + .tgl-btn:after {
          left: 0;
        }
        .tgl + .tgl-btn:before {
          display: none;
        }
        .tgl:checked + .tgl-btn:after {
          left: 50%;
        }

        .tgl-ios + .tgl-btn {
          margin-top: -5px;
          background: #fbfbfb;
          border-radius: 2em;
          padding: 2px;
          transition: all 0.4s ease;
          border: 1px solid #e8eae9;
        }
        .tgl-ios + .tgl-btn:after {
          border-radius: 2em;
          background: #fbfbfb;
          transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease,
            margin 0.3s ease;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
        }
        .tgl-ios + .tgl-btn:hover:after {
          will-change: padding;
        }
        .tgl-ios + .tgl-btn:active {
          box-shadow: inset 0 0 0 2em #e8eae9;
        }
        .tgl-ios + .tgl-btn:active:after {
          padding-right: 0.8em;
        }
        .tgl-ios:checked + .tgl-btn {
          background: #f48d9c;
        }
        .tgl-ios:checked + .tgl-btn:active {
          box-shadow: none;
        }
        .tgl-ios:checked + .tgl-btn:active:after {
          margin-left: -0.8em;
        }
      }
      &-price {
        font-weight: bolder;
        display: flex;
        justify-content: center;
        font-size: 1.6rem;
        align-items: baseline;
        border-bottom: 1.2px solid #e0e0e0c2;
        padding-bottom: 1rem;

        &--text {
          color: #f48d9c;
          font-weight: 900;
          font-size: 3rem;
        }
      }
      &-list {
        text-align: left;
        padding-top: 1rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;

        &--item {
          line-height: 20px;
          margin-bottom: 17px;
          align-items: stretch;
          display: flex;
          img {
            margin-right: 4px;
            margin-bottom: 4.5px;
          }
        }
      }
    }
    &--wrap {
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
    &--title {
      //card-title top-title
      font-weight: bolder;
      display: flex;
      justify-content: center;
      font-size: 1.6rem;
      align-items: baseline;
      border-bottom: 1.2px solid #e0e0e0c2;
      padding-bottom: 1rem;
      margin-bottom: 1.75rem;
    }
  }
  &--footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
