@import "../../../stylesheets/colors";

.Notifications-Container {
  position: absolute;
  width: 100%;
  z-index: 101;
  
  &--private {
    top: 0;
  }
}

.message.Notification {
  top: 0;
  background-color: #f8f4f4;
  border-radius: 0;

  &--success {
    border-bottom: 2px solid $shamrock;
    i { color: $shamrock; }
  }

  &--error {
    border-bottom: 2px solid $primary;
    i { color: $primary; }
  }

  &--info {
    border-bottom: 2px solid $picton-blue;
    i { color: $picton-blue; }
  }
}
