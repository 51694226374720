@import "../../../../../stylesheets/colors";

.ScheduleC {
  display: grid;

  & > div > div {
    display: flex;
    align-items: center;

    &:not(:nth-child(2)) {
      justify-content: space-evenly;
      align-items: center;
    }
    &:nth-child(4) {
      margin: auto 0;
      border: 1px solid #000;
      min-height: 1.5rem;
    }
    &:nth-child(5) {
      margin: auto 0;
      min-height: 1.5rem;
      border-top: 1px solid #000;
      border-right: 1px solid #000;
      border-bottom: 1px solid #000;
    }
  }
}

.category-name {
  display: flex;
  & > div:nth-child(2) {
    flex-grow: 1;
    background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 5px 2px;
    background-repeat: repeat-x;
  }
}

.index {
  text-align: center;
}

.part-1 {
  display: grid;
  grid-template-columns: 30px auto 30px 60px 30px;
  border-bottom: 1px solid #000;
  min-height: 4rem;
  padding-bottom: 1rem;
}

.part-2 {
  display: inherit;
  column-count: 2;
  column-fill: auto;
  & > div {
    display: grid;
    grid-template-columns: 30px auto 30px 100px 30px;
    border-bottom: 1px solid #000;
    min-height: 4rem;
  }
}

.centered {
  text-align: center;
}

.group-title {
  line-height: 2.5rem;
  font-weight: 700;

  & > b {
    background-color: $curious-blue;
    color: $white;
    padding: 0.5rem;
    border-radius: 0.333rem;
    margin-right: 0.5rem;
  }
}
