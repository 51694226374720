.Button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 12px;
  width: 120px;
  border-radius: 50px;
  color: #fff;
  background-color: #e2868f;
  font-size: 12px;
  width: 120px;
  border-radius: 50px;
  display: inline-block;
  transition: all 0.3s ease 0s;

  &:hover {
    text-decoration: none;
    color: #e2868f;
    background-color: #f8f9fa;
    border-color: #e2868f;
  }

  &--bg {
    font-size: 0.9rem;
    width: 166px;
  }

  &--outline {
    color: #8c8c8c;
    border-color: #8c8c8c;
    background-color: white;

    &:hover {
      color: white;
      background-color: #e2868f;
      border-color: #e2868f;
    }
  }

  &--pulse {

    color: white;
    background-color: #e2868f;
    border-color: #e2868f;
    animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
    &:hover {
      background-color: #f87083;
    }
  }
}



@-webkit-keyframes sploosh {
  0% {
    box-shadow: 0 0 0 0px #f48d9c;
    background: #f48d9c;
  }
  80% {
    background: #f48d9c;
  }
  100% {
    box-shadow: 0 0 0 120px #f48d9c;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  3.3% {
    -webkit-transform: scale(1.2);
  }
  16.5% {
    -webkit-transform: scale(1);
  }
  33% {
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

/* Declate color shifting animation */
@keyframes colorShift {
	0%, 100% {
			background: #f48d9c;
	}
	33% {
		background: #f48d9c;
	}
	66%{
		background: #f87083;
	}
}

/* Declare border pulse animation */
@keyframes borderPulse {
  0% {
    box-shadow: inset 0px 0px 0px 5px #f48d9c, 0px 0px 0px 0px #f48d9c;
  }
  100% {
    box-shadow: inset 0px 0px 0px 3px white, 0px 0px 0px 10px white;
  }
}