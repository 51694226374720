.NewInvitation__Container {
  padding-left: 2em;
  padding-right: 2em;
}

button.ui.button {
  &.NewInvitation__Button__Cancel {
    text-transform: uppercase;
    color: #474747;
    background: #fff none;
  }

  &.NewInvitation__Button__SendInvite {
    text-transform: uppercase;
  }
}

.NewInvitation__Buttons {
  display: flex;
  justify-content: flex-end;
}
