.Requests__Row__Email {
  color: #0f7dff;
}

.Requests__Row__AccountingPro {
  color: #0f7dff;
  font-weight: bold;
}

#Requests__Container.ui.table {
  border-collapse: separate;

  thead th {
    padding-bottom: 1.5em;
  }
}

#Requests__Container.ui.celled.table tr {
  th {
    border-left: 0px;
  }

  td {
    border-left: 0px;
  }
}

#Requests__Container.ui.basic.table {
  border: 0px;
}

button.ui.button {
  &.Requests__Row__DeclineButton {
    text-transform: uppercase;
    background: #fff none;
  }

  &.Requests__Row__ApproveButton {
    text-transform: uppercase;
  }
}
