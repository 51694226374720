@import "../../../../../stylesheets/colors";

.ProfileDetailsReadOnly {
  .profile-detail {
    padding: 0;
    display: flex;
    align-items: center;
    gap: 20px;
    overflow: hidden;

    @media (max-width: 992px) {
      gap: 10px;
    }
  }

  .ProfileDetailsReadOnly-Content {
    text-align: left;
  }

  h6 {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.8px;
    color: #b2bac4;
    padding: 0px 20px;
    margin: 0px;
  }

  &__FullName {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #34495e;
    margin-top: 5px;
  }

  &__Email {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #34495e;
  }

  &__footer {
    line-height: 1.36;
    letter-spacing: 0.3px;
    color: #34495e;
    background: #b2bac4;
    text-align: left;

    > div > div:first-of-type { font-weight: bold; }
  }
}

.ui.segment.ProfileDetailsReadOnly-ImgName {
  align-items: center;
  display: flex;
  width: 5rem;
  height: 5rem;
  font-size: 1.5rem;
  justify-content: space-evenly;
  background-color: $alabaster;
  font-weight: bold;
  padding: 0px;

  @media (max-width: 992px) {
    width: 3.75rem;
    height: 3.75rem;
    font-size: 1rem;
  }

  & > div {
    font-size: 2rem;
    color: $chambray;
  }
}
