
.ui.dropdown.icon.button > .dropdown.icon {
  float: right;
}

.select-action{
  width: 100%;
  min-width: 100px;
  & .ui.dropdown > .text {
    width: 70px;
  }
}
.more-action{
  width: 100px;
  min-width: 70px;
  & .ui.dropdown > .text {
    width: 70px;
  }
}
.tax-action{
  min-width: 70px;
  padding:0px 5px;
  & .ui.dropdown > .text {
    width: 90px;
    height: 15px;
    overflow: hidden;
  }
}
