@import "../../../stylesheets/colors";

.ui.stackable.Balance__Container {
  padding: 4px;
}

.Date__Select {
  color: #9F3A38;
  margin: 8px;
}

.AccountTable {
  margin: 14px 0;
}