.Create {
  &--title {
    font-size: 30px;
    margin: 0px 10px;
  }
  &--subtitle {
    font-size: 25px;
    margin: 0px 10px;
  }
  &--invoice {
    margin-top: 10px !important;
  }
  &--left {
    float: left;
  }
  &--label {
    width: 120px !important;
    text-align: right;
    margin-right: 20px !important;
    font-size: 16px !important;
  }
  &--input {
    width: 196px !important;
    height: 38px !important;
  }
  &--icon {
    margin-left: -10px !important;
    font-size: 24px !important;
    vertical-align: text-top;
    color: steelBlue;
    margin-top: 10px !important;
  }
  &--icon:hover {
    color: #3a5695;
    cursor: pointer;
  }
  &--business {
    font-size: 20px;
    margin: 30px 0px;
  }
  &--field {
    height: 30px;
    display: flex;
    align-items: center;
  }
  &--manage {
    margin-top: 10px !important;
  }
  &--advanced-search {
    text-decoration: underline;
    color: #f48d9c;
  }
  &--tbcontrol {
    background-color: #eee;
  }
  &--grid-item {
    display: "flex";
    align-items: "center";
  }
  &--divider {
    width: 100%;
    transform: none;
    -webkit-transform: none;
    border-color: lightGray;
  }
  &--table {
    tr,
    td {
      border: 2px solid lightGray;
      height: 50px;
    }
    .dropdown {
      min-height: auto;
    }
    text-align: right;
    border: 1px solid #ddd;
    border-collapse: collapse;
    font-size: 15px;
    width: 100%;
  }
  &--td-text {
    padding: 0px 15px;
  }
  &--item-control:hover {
    cursor: pointer;
    color: #3a5695;
  }
  &--add-item:hover {
    cursor: pointer;
    color: #3a5695 !important;
    font-size: 17px;
  }

  & .ui.fluid.input.table--input > input {
    border: none;
  }
  & .ui.fluid.input.table--input--right > input {
    border: none;
    text-align: right;
  }

  & .opacity-pan {
    width: 100%;
    height: 100%;
    background: lightgrey;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.3;
  }

  & .currency-input {
    border: none;
    text-align: right;
    padding: 5px;
    width: 100%;
    outline: none;
  }
  & .currency-input:focus-visible {
    border: none !important;
    outline: none;
  }
  & .word-wrap{
    overflow-wrap: break-word;
  }
}
