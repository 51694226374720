@import "../../../stylesheets/colors";

.Clients__Row__Email {
  color: #0f7dff;
}

.Clients__Row__AccountingPro {
  color: #0f7dff;
  font-weight: bold;
}

#Clients__Container.ui.table {
  border-collapse: separate;

  thead th {
    padding-bottom: 1.5em;
  }
}

#Clients__Container.ui.celled.table tr {
  th {
    border-left: 0px;
  }

  td {
    border-left: 0px;
  }
}

#Clients__Container.ui.basic.table {
  border: 0px;
}

p.RemoveClient__Confirm_Text {
  font-size: 16px;
  & span {
    font-weight: bold;
  }
}

button.ui.button {
  &.Clients__Row__ViewBooksButton {
    text-transform: uppercase;
    color: #ef778b;
    background: #fff none;

    > .Clients__Row__BookIcon {
      padding-left: 1em;
    }
  }

  &.Clients__Row__RemoveButton {
    text-transform: uppercase;
    color: $gray;
    background: #fff none;

    &:hover {
      background-color: $gray;
      color: white;
    }

    &:active {
      opacity: .9;
    }

    > .Clients__Row__RemoveIcon {
    }
  }
}
