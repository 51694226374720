@import "../../../stylesheets/colors";

.ui.dropdown.Dashboard__Avatar--menu {
  color: $dusty-gray !important;
  font-size: 1.3rem;
  z-index: 9999;

  & > .visible.menu.transition {
    margin-top: 0;
    border: 1px solid $primary;
    min-width: 10rem;
    z-index: 105;
    & > div[role="option"] {
      overflow: hidden;
    }
  }
  & span {
    font-size: 1rem;
  }
}

.ui.dropdown.active.Dashboard__Avatar--menu,
.ui.dropdown.Dashboard__Avatar--menu:hover {
  color: $dusty-gray !important;
  background: transparent !important;
}

@media only screen and (max-width: 767px) {
  .ui.dropdown.Dashboard__Avatar--menu {
    justify-content: flex-end;
  }
}
