@import "../../../stylesheets/colors";

.PublicLayout {
  &--header {
    background: $porcelain;
  }

  &--footer {
    background: #e9ecef;
    padding: 15px 0px;
    margin-top: 3em;
    text-align: center;
  }
}

a.ui.basic.button.PublicLayout__Pricing_Button {
  color: $chambray !important;
  background: transparent !important;
  border: 1px solid $chambray;
  &:hover {
    background-color: $chambray !important;
    color: $white !important;
  }
}

.divider {
  transform: rotate(90deg);
  width: 3rem;
  height: 0;
  border-bottom: 1px solid $gray;
}

.vertical-center {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .ui.stackable.menu {
    padding: 1rem 0;
    .right.menu {
      flex-direction: row;
      justify-content: space-between;
      & > .item {
        width: calc(100% / 3) !important;
      }
    }
  }
  .divider {
    display: none;
  }
}

.PublicLayout__Footer__ContactUs {
  padding: 0.5rem 0;
  & > a {
    color: $gray;
    &:hover { color: $primary; }
  }
}
