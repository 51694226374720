@import "../../../stylesheets/colors";

.SyncPlaidAccountsTable {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  .ui.plaid-account {
    display: inline-block;
    line-height: 1em;
    &.add-account {
      height: 2em;
      width: 2em;
      padding: 0.25em;
      font-size: 4em;
      margin: 0;
      border-style: dashed;
    }
    &.bank-item {
      display: flex;
      position: relative;
      flex-flow: column;
      margin: 0px 1rem 0em 0em;
      border: 1px solid rgba(0, 0, 0, 0.05);
      transition: border-color 350ms ease-in-out;
      &:hover {
        border-color: rgba(0, 0, 0, 0.15);
      }
      .bank-logo {
        height: 65%;
        width: 125px;
        padding-top: 9%;
        position: relative;
        text-align: center;
        > img {
          width: 50%;
          display: unset;
          margin: 0px auto;
        }
        > i.not-found {
          top: 30%;
          left: 30%;
          position: relative;
        }
        &:not(.disableDelete):hover .btn-delete {
          display: block;
        }
        .btn-delete {
          display: none;
          background: $gulf; /* Old browsers */
          cursor: pointer;
          position: absolute;
          padding: 2px 4px;
          top: 0;
          right: 0;
          z-index: 1;
          i { color: white; margin: 0px auto; }
        }
      }
      .bank-name {
        font-size: .8em;
        text-align: center;
        background: rgba(0, 0, 0, 0.05);
        max-width: 125px;
        padding: 5px;
        flex-grow: 1;
      }
    }
  }
}
