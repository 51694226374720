.ui.icon.sync.message {
  margin: 1em 0em;
  &.profile {
    width: 90%;
    margin: 15px 24px 0;
  }
  &.dashboard, &.negative {
    border-radius: 0;
    border: 2px solid;
    border-top-width: 8px;
  }
  &.dashboard {
    background: white;
    border-color: #3a5695;
  }
  &.negative {
    border-color: #902c2b;
  }
}
.ui.warning.accounts-warning.message {
  margin: 1em 0em;
}
