.row.ECTransaction__ButtonRow {
  padding-left: 2em !important;
}

.ui.grid.Modal__Content-Labels-Container {
  padding-left: 9em;
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .ui.container.Modal__Content  {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .ui.container.Modal__Content  {
    overflow-y: auto;
    overflow-x: hidden;
  }
}