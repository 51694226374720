@import "../../../stylesheets/colors";

.Filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column-reverse;
  align-items: flex-start;
  &__Tabs {
    div.ui.pointing.secondary.menu { border-bottom-color: transparent; }
  }
  &__Segment__Grid { background: #f8f4f4; }

  &__Custom__Column__Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 14px !important;
  }

  & > div:last-child {
    margin-left: auto !important;
    margin-right: 0 !important;
  }
  @media (min-width: 992px) {
    flex-direction: row;
  }
}

.Filters__Range-Divider::after {
  content: 'to';
  margin: 0 0.5rem;
}

.ui.segment.Filters__Segment {
  background-color: $soft-peach;
}

.ui.checkbox input.hidden + label {
  color: $black;
  margin: 4px;
  font-weight: normal;
}
