@import "../../../../stylesheets/colors";

.ui.label.ECTransaction__Type {
  background-color: white;
  color: $anakiwa;
  border: solid 1px;
  border-radius: 50px;
  
  &.--Active {
    background-color: $curious-blue;
    color: white;
    border-radius: 50px;
  }
}

p.ECTransaction__Type {
  color: $primary;
  text-align: left;
}