
.NewTransactionsNotificator__Container {
  margin: 10px 0px;
  padding: 10px 30px;
  border: 2px solid #f49c20;
  border-top-width: 8px;
  box-shadow: 0 3px 5px 0 #eee;
  background-color: #fff;
}

.NewTransactionsNotificator__Prior {
  color: #fe5a72;
}