@import "../../../stylesheets/colors";

.ui.segment.Transactions {
  &__Container {
    background-color: $white;
    display: flex;
    flex-direction: column;
    padding: 0;
    border: none;
    box-shadow: none;

    > table {
      color: #34495e;
      > thead tr th:not(:first-child) { font-size: 11px; }
      > tbody tr td:nth-child(7) { color: #2aa7dc; }
      > tbody tr td:nth-child(8) { color: #3a5695; }
    }
  }
}

.ui.container.Transactions__Pagination-container {
  margin-bottom: 1rem;

  & .ui.pagination.menu {
    border: 0;
    box-shadow: none;
  }

  & .ui.menu .item {
    border: solid 1px rgb(51, 76, 138);
    margin: 0 0.25rem;
    outline: none;

    &.active {
    border-color: $primary;
    background-color: $primary;
      color: $white;
    }
  }
}

.ui.table {
  border-collapse: collapse;
}

.ui.table thead th.Transactions__Header--HeaderCell, .ui.table tbody tr {
  cursor: pointer;
}

.ui.table tr.Transactions__Row {
  border-left: 4px solid $chambray;
  &:hover {
    cursor: pointer;
    background-color: $mercury;
  }

  &.--Success {
    border-left: 4px solid $shamrock !important;
  }

  &.--Info {
    border-left: 4px solid $picton-blue !important;
  }

  &.--Error {
    border-left: 4px solid $primary !important;
  }
}

img.ui.mini.image {
  width: 10px;
}

.ui.basic.label {
  &.--Success {
    background: none;
    border: 1px solid $shamrock;
    color: $shamrock;
  }
  &.--Info {
    background: none;
    border: 1px solid $picton-blue;
    color: $picton-blue;
  }
  &.--Error {
    background: none;
    border: 1px solid $primary;
    color: $primary;
  }
}