@import "../../../../stylesheets/colors";

.ui.segment.Balance-Table__Top {
  display: flex;
  background-color: $gulf;
  color: $white;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.Balance-Table {
  margin-top: 1rem;
}
