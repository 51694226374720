@media print {

  @page { margin: 1cm }

  *,
  *:before,
  *:after {
    color: black !important; // Black prints faster: h5bp.com/s
    text-shadow: none !important;
    background: transparent !important;
    box-shadow: none !important;
    font-size: 9pt !important;
  }

  img { max-width: 100% !important; }

  div.ui.container {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    float: none !important;
  }

  body {
    font: 9pt Verdana, "Times New Roman", Times, serif !important;
    line-height: 1.3 !important;
  }

  /* Defining all page breaks */
  a { page-break-inside: avoid; }
  blockquote { page-break-inside: avoid; }
  h1, h2, h3, h4, h5, h6 { page-break-after: avoid; page-break-inside: avoid; }
  img { page-break-inside: avoid; page-break-after: avoid; }
  table, pre { page-break-inside: avoid; }
  ul, ol, dl  { page-break-before: avoid; }

  /* Displaying link color and link behaviour */
  a:link, a:visited, a {
    background: transparent !important;
    color: black !important;
    font-weight: bold !important;
    text-decoration: underline !important;
    text-align: left !important;
  }
  a { page-break-inside: avoid; }
  a[href^=http]:after { content:" <" attr(href) "> "; }

  /* Hiding unnecessary elements for the print */
  .HideForPrint { display: none !important; }

  /* Custom styling */
  .Profit-Summary,
  .Profit-Summary__Container,
  .Profit-Summary__Content {
    height: 180pt !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .Profit-Summary__Filters--Profit {
    font-size: 13pt !important;
  }
}
