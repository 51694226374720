.Footer {
  padding-bottom: 3rem;
  background-image: url('../../../images/footer-bg.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* background-position: center; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 70vh;
  align-items: center;

  &--logo {
    display: block;
    max-width: 100%;
    border-radius: 0.25rem !important;
    margin-top: 165px;
  }

  &--contact-us {
    text-decoration: none !important;
    color: black;
    &:hover {
      color: #e2868f !important;
    }
  }

  &--credits {
    text-align: center;
    background-color: #373c4c;
  }
  &--copyrights {
    padding: 24px;
    margin-bottom: 0px !important;
    color: white;
  }

  &--social-icons {
    margin: 0;
    display: flex;
    justify-content: space-around;

    .left {
      border-right: 1px solid #7d7d7d;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 55px;
      align-items: center;
      @media (max-width: 420px) {
        & {
          padding-right: 35px !important;
        }
      }
    }
    .right {
      padding-left: 55px;
      border-left: 1px solid #7d7d7d;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      @media (max-width: 420px) {
        & {
          padding-left: 35px !important;
        }
      }
    }
    .center {
      justify-content: flex-start !important;
    }
    img {
      max-width: 110px !important;
      width: 60px !important;
      border-radius: 0.25rem !important;
      display: block;
    }
  }
}
