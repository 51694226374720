.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;

  &-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  &-expand &-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  &-expand &-toggler {
    display: none;
  }

  &-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: '';
    background: no-repeat center center;
    background-size: 100% 100%;
  }
  .collapse:not(.show) {
    display: none;
  }

  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }

  &-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
  }
  &-expand &-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  &-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  &-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  &-nav .dropdown-menu {
    position: static;
    float: none;
  }

  &-expand &-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  &-expand &-nav .dropdown-menu {
    position: absolute;
  }

  &-expand &-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .ml-auto,
  .mx-auto {
    margin-left: auto !important;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }

  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  }
  .nav-link:hover,
  .nav-link:focus {
    text-decoration: none;
  }

  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
  }

  .d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }

  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }
  &-nav {
    margin-top: 0px;
  }

  &-toggler-icon {
    margin-top: -16px;
  }

  #nav-toggle {
    cursor: pointer;
    padding: 10px 35px 16px 0px;
  }
  #nav-toggle span,
  #nav-toggle span:before,
  #nav-toggle span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 35px;
    background: #e2868f;
    position: absolute;
    display: block;
    content: '';
  }
  #nav-toggle span:before {
    top: -10px;
  }
  #nav-toggle span:after {
    bottom: -10px;
  }

  #nav-toggle span,
  #nav-toggle span:before,
  #nav-toggle span:after {
    transition: all 300ms ease-in-out;
  }
  #nav-toggle.active span {
    background-color: transparent;
  }
  #nav-toggle.active span:before,
  #nav-toggle.active span:after {
    top: 0;
  }
  #nav-toggle.active span:before {
    transform: rotate(45deg);
  }
  #nav-toggle.active span:after {
    transform: rotate(-45deg);
  }

  /* end */

  /* navbar */

  &-toggler:hover,
  &-toggler:focus {
    outline: none;
  }

  &-wrap {
    display: flex;
    justify-content: center;
  }
  nav {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .nav-menu-item {
    color: grey;
    font-size: 13px;
  }
  .nav-link:hover {
    color: #e2868f !important;
  }

  .pl-1,
  .px-1 {
    padding-left: 0.25rem !important;
  }
  .pr-1,
  .px-1 {
    padding-right: 0.25rem !important;
  }
  @media (min-width: 992px) {
    & {
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    &-nav {
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    &-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    &-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    &-toggler {
      display: none;
    }
  }
  @media (max-width: 992px) {
    .mt-0 {
      margin-top: 0px;
    }

    li.nav-item {
      padding-left: 7px;
      padding-top: 7px;
    }
    div#navbarNavAltMarkup {
      margin-top: 24px;
      border-top: 1px solid rgb(237, 237, 237);
      border-bottom: 1px solid rgb(237, 237, 237);
    }

    a.nav-link.pr-1 {
      padding-left: 0px;
    }
    a.nav-link.pl-1 {
      padding-left: 0px !important;
    }
    .rpb {
      padding-bottom: 0px;
    }
    .rpt {
      padding-top: 0px;
    }
  }
}
