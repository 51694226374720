@import "../../../stylesheets/colors";

.ui.menu {
  border-radius: 0;
  margin-bottom: 0;

  &:not(.vertical) .item {
    align-items: flex-end;
  }
}

.Layout__Background--color-gray {
  background-color: $alabaster;
}

.Layout__Body {
  height: 100%;
  padding-top: 2rem;
  position: relative;
}

.ui.dimmer.Layout__Loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.87);

   & .ui.loader {
    color: $white;
    font-style: bold;
  }
}
