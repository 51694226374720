.list-invitee-container * {
  margin: 5px 0;
}

.list-invitee-container {
  border: 1px solid lightgrey;
}

.ui.header {
  &.ListInvitee__Header {
    background: #f4f4f4 none;
    color: #7a7a7a;
    padding: 0.4em 1em;
    margin: 0;
  }
}

.ShowInvitee__Container:not(:last-child) {
  border-bottom: 1px solid #f4f4f4;
}
