@import "../../../../stylesheets/colors";

.Profit-And-Loss__Segment {
  overflow-x: scroll;
  min-height: 200px;
}

.Profit-And-Loss__Category {
  &-Title {
    color: $chambray;
    font-weight: 700;
  }
  &-Amount {
    color: $chambray;
    font-weight: 700;
  }
}

.Profit-And-Loss__Cell--Item {
  color: $gulf;
}

.ui.table.Profit-And-Loss {
  thead tr th:first-child {
    width: 16rem;
    border: 1px solid $gulf;
  }
  thead tr th:not(:first-child) {
    width: 8rem;
    border: 1px solid $gulf;
    color: $gulf;
    font-weight: bold;
  }
  table-layout: fixed;
  & thead {
    border-bottom: 2px solid $gulf;
  }

  & tr td {
    border: 1px solid rgba(114, 158, 194, 0.6);
    word-break: break-word;
  }
}

.ui.one.column.table td {
  width: auto;
  padding: 4px;

  &.--Other {
    padding-left: 1.2rem;
  }

  &.--Total {
    color: $chambray;
    font-weight: 700;
  }
}
