@import "../../../stylesheets/colors";

.Reports-Segments {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-height: 22rem;
  padding-top: 3rem;
}

.ui.header.Reports__Title {
  color: $gulf;
}

.Reports__Segment {
  color: $kashmir-blue;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 13rem;
  &:hover {
    color: $glacier;
  }
  & > .icon.massive {
    font-size: 6rem;
  }
  &--Audit-Trail > button.ui.button.medium.primary {
    padding-right: 3rem !important;
  }
  & > button { min-height: 50px !important; }
}
