.Terms-And-Privacy {
  margin-top: 5rem;
}

.justified {
  text-align: justify;
}

h4 {
  text-decoration: underline;
}

ol {
  counter-reset: item;
}

ol > li {
  counter-increment: item;
}

ol ol > li {
  display: block;
}

ol ol > li:before {
  content: counters(item, ".") ". ";
  margin-left: -20px;
}

li {
  margin: 1rem 0;
}

ul {
  list-style-type: disc;

  &:before {
    font-weight: 700;
  }
}
