@import "../../../stylesheets/colors";

.BulkEdit__Container {
  margin-top: 1em;
  padding: 1rem;
  background-color: $cadet-blue;
  margin-bottom: 1.5em;
}

.ten.wide.column.BulkEdit__Button {
  text-align: right;
  & > .ui.button {
    color: white;
    background-color: $pickled-bluewood;
  }
}

.six.wide.column.BulkEdition__Total {
  & > .ui.header {
    color: white !important;
    font-size: 1.2rem;
  }
  padding-top: 0.6em;
  padding-left: 1.5em;
}
