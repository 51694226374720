@import "../../../stylesheets/colors";

.ui.stackable.Budgeting__Container {
  padding: 4px;

  & .Budgeting__Table.ui.fixed.table th, .ui.fixed.table td {
    word-break: break-all;
    padding: 0.92857143em 0.58571429em;
    font-size: 0.9rem;
  }

  & .Budgeting__Table.ui.fixed.table td.Others__CategoryName {
    padding-left: 2.5rem;
  }
}

.fields.Category__Group__Row {
  border-left: 4px solid $chambray;

  &.--Income {
    border-left: 4px solid $curious-blue
  }
}

.ui.divider.Budgeting__divider {
  transform: rotate(0deg);
  width: 100%;
  margin: 8px 0;
}

.field.NewItem__Close {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.field.NewItem__ADD {
  display: flex;
  justify-content: flex-end;
}

.ui.centered.grid .Categories__BreadCrumb {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.2rem;
  & a {
    padding-left: 12px;
    text-decoration: none;
    cursor: pointer;
    color: black;
  }
  & span.active {
    color: $chambray;
  }
}

div .wide.column.budgeting--YTDShow {
  display: flex;
  align-items: center;
}

.budgeting--MonthRangeGrid div {
  display: flex;
  justify-content: space-around;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
}

.ui.SwitchChart {
  float: right;
}

.Budgeting__Title {
  font-weight: bold;
  font-size: 1.2rem;
  padding: 1rem 1rem 0 1rem;
}

.column.Chart__Top__Title {
  font-weight: bold;
  font-size: 1rem;
}

.ui.grid.Chart__Top__Bar {
  display: flex;
  align-items: center;
}